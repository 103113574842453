

.App .ant-layout-header,
.App .ant-layout-header ul {
    background-color: #ddd;
}

@media (max-width: 767px) {
    .App .ant-layout-header {
        padding: 0 10px;
    }
}

.App .ant-layout-header .logo {
    font-weight: bold;
    font-size: large;
    margin-right: 30px;
}

.App .ant-anchor-wrapper {
    background-color: #f0f2f5;
}

.drawer-menu .ant-drawer-body {
    padding: 0;
}
