
.receiver { 
	height: 100vh;
	padding: 5% 10%;
}

.title h1 {
	/*font-size: 3em;*/
	font-size: 42px;
}


/* Howto:
 *   - https://css-tricks.com/solved-with-css-logical-styling-based-on-the-number-of-given-elements/
 */
.receiver table {
	/*font-size: 2em;*/
	font-size: 28px;
}

.receiver table tfoot tr {
	background-color: #f0f0f0;
}

.receiver table tbody tr {
	font-size: 24px; 	/* default */
	padding: 16px;		/* default */
}

.receiver table tbody tr:first-child:nth-last-child(n+3),
.receiver table tbody tr:first-child:nth-last-child(n+3) ~ * {
	font-size: 20px;
}

.receiver table tbody tr:first-child:nth-last-child(n+3) td,
.receiver table tbody tr:first-child:nth-last-child(n+3) ~ * td {
	padding: 10px;
}

.receiver table tbody tr:first-child:nth-last-child(n+5),
.receiver table tbody tr:first-child:nth-last-child(n+5) ~ * {
	font-size: 18px;
}

.receiver table tbody tr:first-child:nth-last-child(n+5) td,
.receiver table tbody tr:first-child:nth-last-child(n+5) ~ * td {
	padding: 4px;
}


.receiver button {
	display: none;
}

.receiver .info {
	display: none;
}
